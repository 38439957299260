import { ActionFunctionArgs, json, type MetaFunction } from '@remix-run/node';
import { Form, Link } from '@remix-run/react';
import { useForm } from 'react-hook-form';
import ErrorMessageClient from '@components/client/ErrorMessageClient';
import { useCustomSubmit } from '@hooks/useCustomSubmit';
import { createClientUserSession, postClientLogin } from '@server/login/login.client.server';
import { ClientLoginInDTO, ClientLoginResolver, LoginStateDTO } from '@server/login/login.models';
import { ApiResponseMessage } from '@server/models/common.models';
import { customToast } from '@server/toast.server';

export const meta: MetaFunction = () => {
    return [{ title: 'AunionAI' }, { name: 'description', content: '[AunionAI] 메인 페이지' }];
};

export const action = async ({ request }: ActionFunctionArgs) => {
    const formData = await request.json();

    const result = await postClientLogin(formData);

    if (result.status === 200) {
        return await createClientUserSession({
            request,
            redirectTo: '/',
            logininfo: {
                ...result.content!,
            },
        });
    }

    return json<ApiResponseMessage<LoginStateDTO>>(result, {
        headers: await customToast<LoginStateDTO>('error', result),
    });
};

export default function ClientLogin() {
    const { submit } = useCustomSubmit();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const onSubmit = (data: ClientLoginInDTO) => {
        submit(data);
    };

    return (
        <div className="form_list">
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="unit">
                    <p className="title">E-mail</p>
                    <input
                        className="login_input"
                        type="text"
                        placeholder="Please enter your e-mail"
                        {...register('email', {
                            required: 'Please enter your e-mail',
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: 'Please enter a valid email address.',
                            },
                        })}
                    />
                    {errors.email && <ErrorMessageClient message={errors.email.message} />}
                </div>
                <div className="unit">
                    <p className="title">Password</p>
                    <input
                        className="login_input"
                        type="password"
                        placeholder="Please enter your password."
                        {...register('password', {
                            required: 'Please enter your password.',
                        })}
                    />
                    {errors.password && <ErrorMessageClient message={errors.password.message} />}
                </div>
                <button className="login_btn">LOGIN</button>
                <Link to="/pw-confirm" className="forgot_pw">
                    Forgot your <em>password?</em>
                </Link>
            </Form>
        </div>
    );
}
